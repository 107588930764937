import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import IconCircle from '@veneer/core/dist/scripts/icons/icon_circle'
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import { SortTypes, TableSortBy } from '@veneer/core/dist/scripts/table'
import ContextualMenu from '@veneer/core/dist/scripts/contextual_menu'
import MenuItem from '@veneer/core/dist/scripts/menu_item'
import MenuList from '@veneer/core/dist/scripts/menu_list'
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast'
import { OptionInterface, useTheme } from '@veneer/core'
import { ErrorWidget, SmartTooltip } from '@jarvis/react-portal-addons'
import useRootContext from '../../../contexts/Root/useRootContext'
import usePreferencesContext from '../../../contexts/Preferences/usePreferencesContext'
import { getl10n } from '../../../utils/localizationMap'
import {
  addDataTestIdToNoItems,
  addDataTestIdToPagination,
  deSelectRow,
  disableTitleOnSvgIcon,
  filter,
  handleMultipleUsers,
  mergeData,
  selectionState,
  selectRow,
  sort,
  getUserFullName,
  handleResetToDefaultState
} from '../../../utils/commonMethods'
import { getThemeColor, isColorFromTheme } from '../../../utils/color'
import { defaultPageSizeOptions, STORAGE_COLUMN_USER } from '../../../utils/constants'
import AssignRoleModal from '../../../shared-components/Modals/AssignRoleModal'
import ContextualFooter from './contextualFooter'
import RemoveUserModal from '../../../shared-components/Modals/RemoveUserModal'
import ResendInvitationModal from '../../../shared-components/Modals/ResendInvitationModal'
import { ActiveUser, deletedItemProps, User, UserData } from '../../../interfaces/manageUsersInterface'
import { assignExternalUserRole, assignUserTenantRole, deleteInvitation, resendInvitation } from '../../../utils/api'
import { ContinueButtonClicked, publishEvent } from '../../../utils/analytics'
import { getCountByRole } from '../../../utils/customRoles'
import { UserListColumnType, UserListConstants, UserListPreferences } from '../../../utils/userListColumns'
import { PermissionSet, getPermissionSetById } from '../../../utils/permissionSet'
import { Table, UserName, UserStatus, ColumnPiece, ContextualMenuArea, UserInfo, ReorderColumnPortal } from './styles'

const defSortOrder: SortTypes = 'ascending'

const defSorting: TableSortBy = {
  id: UserListConstants.name,
  type: defSortOrder
}

const initialState = {
  x: 0,
  y: 0
}

const getStatusIconColor = (state, mapping) => {
  const color = mapping?.[state?.toLowerCase()]
  const isFromTheme = isColorFromTheme(color)

  if (isFromTheme) {
    return undefined
  }

  const colorState = {
    active: mapping?.active || 'green7',
    expired: mapping?.expired || 'red3',
    pending: mapping?.pending || 'gray5'
  }
  return colorState[state?.toLowerCase()]
}

const getStatusIconThemeColor = (state, mapping, theme) => {
  const color = mapping?.[state?.toLowerCase()]
  const isFromTheme = isColorFromTheme(color)

  if (!isFromTheme) {
    return {}
  }
  return { color: getThemeColor(color, theme) }
}

export type UsersProps = {
  actionArea?: JSX.Element
  activeUser?: ActiveUser
  columnReorder?: boolean
  deleteScopeForUser?: boolean
  isLoading: boolean
  userListColumns: Array<UserListColumnType>
  retrieveUsers: () => void
  searchItem: string
  searchItems?: string[]
  users: User[]
  writeScopeForAssignRole?: boolean
  writeScopeForInvite?: boolean
  permissionSet: PermissionSet
  hasError: boolean
  rolesDataSource: string
}

const Users = ({
  actionArea,
  activeUser,
  columnReorder,
  deleteScopeForUser,
  isLoading,
  hasError,
  retrieveUsers,
  searchItem,
  searchItems,
  users,
  writeScopeForAssignRole,
  writeScopeForInvite,
  permissionSet,
  rolesDataSource,
  userListColumns
}: UsersProps) => {
  const { stack, shell } = useRootContext()
  const { localization, authProvider } = shell
  const { t } = localization.useReactTranslatorHook()
  const { addToast } = useToast()
  const theme = useTheme()

  const { manageUsers: manageUsersPreferences } = usePreferencesContext()
  const {
    table: tablePreferences,
    hideContentModalRemoveUser,
    showRemoveUserModalSubcontent,
    displayColumnResetText,
    useGlobalHeader
  } = manageUsersPreferences || {}
  const {
    displayFullContextualMenu,
    displayGroupColumn,
    displayNoResultsFilterText,
    displaySelectAllItemsButton,
    hideSolutionsColumn,
    pageSizeOptions,
    popoverPlacement,
    showEmailInTooltip,
    statusColorMapping,
    userListColumnDefaultOrder
  } = tablePreferences || {}

  const [columns, setColumns] = useState(userListColumns)
  const [currentPage, setCurrentPage] = useState<number>(UserListConstants.currentPage)
  const [deletedRowId, setDeletedRowId] = useState<deletedItemProps>()
  const [disableResendInvitation, setDisableResendInvitation] = useState<boolean>(false)
  const [handleClickTriggered, setHandleClickTriggered] = useState(false)
  const [orderBy, setOrderBy] = useState<string>(UserListConstants.fullName)
  const [orderColumn, setOrderColumn] = useState<string[]>(userListColumnDefaultOrder)
  const [orderType, setOrderType] = useState<string>(UserListConstants.ascending)
  const [defOrderBy, setDefOrderBy] = useState<TableSortBy>(defSorting)
  const [pageSize, setPageSize] = useState<number>(UserListConstants.pageSize)
  const [position, setPosition] = useState(initialState)
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<User>()
  const [showAssignRoleModal, setShowAssignRoleModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showResendInvitationModal, setShowResendInvitationModal] = useState<boolean>(false)
  const [storageLoaded, setStorageLoaded] = useState(false)
  const [totalItems, setTotalItems] = useState<number>(users?.length || 0)
  const [usersData, setUsersData] = useState<UserData[]>([])
  const [userPageData, setUserPageData] = useState<UserData[]>([])
  const [isContextualMenuOpen, setIsContextualMenuOpen] = useState<boolean>(false)
  const [disableResetToDefault, setDisableResetToDefault] = useState<boolean>(true)

  const activeUserTenantId = activeUser?.tenantResourceId || ''

  const selectedItems = useMemo(() => usersData.filter((data) => data.rowConfig.selected), [usersData])

  const numberOfSelectedItems: number = selectedItems.length
  const storageColumn = STORAGE_COLUMN_USER + '_' + activeUserTenantId
  const orderSaved = localStorage.getItem(storageColumn)

  const reorderColumnRef = useRef(null)

  const contextualMenuItems = useMemo(() => {
    let items = []

    if (displayFullContextualMenu) {
      items = [
        {
          value: UserListConstants.assignRole,
          label: t(getl10n('actionAssignRole')),
          'data-value': t(getl10n('actionAssignRole')),
          'data-testid': 'assign-role-menu-item'
        },
        {
          value: 'assignSolutions',
          label: t(getl10n('actionAssignSolutions')),
          disabled: true,
          'data-value': t(getl10n('actionAssignSolutions')),
          'data-testid': 'assign-solutions-menu-item'
        },
        {
          value: UserListConstants.resendInvitation,
          label: t(getl10n('actionAssignResendInvitation')),
          disabled: disableResendInvitation,
          'data-value': t(getl10n('actionAssignResendInvitation')),
          'data-testid': 'resend-invitation-menu-item'
        },
        {
          value: UserListConstants.removeUser,
          label: t(getl10n('actionRemoveUser')),
          'data-value': t(getl10n('actionRemoveUser')),
          'data-testid': 'remove-user-menu-item'
        }
      ]
    }
    return items
      .filter((option) => !(option.value === 'assignSolutions' && hideSolutionsColumn))
      .map((option) => <MenuItem key={option.value} {...option} />)
  }, [disableResendInvitation, displayFullContextualMenu, hideSolutionsColumn, t])

  const onDeleteItem = useCallback((deletedItem: deletedItemProps) => {
    setDeletedRowId(deletedItem)
  }, [])

  const handleClick = useCallback((event: React.MouseEvent, index) => {
    event.preventDefault()
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setHandleClickTriggered(true)
    const div = document.getElementById(index)
    const row = div.parentElement.parentElement

    row.setAttribute('id', 'active-row')
  }, [])

  const buildUsersData = useCallback(
    (usersData: User[]) => {
      const getUserName = (user: User) => {
        return (
          <UserInfo>
            {showEmailInTooltip && (
              <>
                <SmartTooltip
                  content={user.givenName || user.familyName ? `${user.givenName} ${user.familyName}` : '-'}
                  portal
                  useJsPositioning
                  contentShowDelayOnHover={0}
                  contentHideDelayOnHover={0}
                >
                  <UserName className="label" data-testid="user-name-label">
                    {getUserFullName(user.givenName, user.familyName)}
                  </UserName>
                </SmartTooltip>
                <SmartTooltip
                  content={user.email}
                  portal
                  useJsPositioning
                  contentShowDelayOnHover={0}
                  contentHideDelayOnHover={0}
                >
                  <span className="caption-small" data-testid="user-email">
                    {user.email}
                  </span>
                </SmartTooltip>
              </>
            )}
            {!showEmailInTooltip && (
              <SmartTooltip
                content={user.givenName || user.familyName ? `${user.givenName} ${user.familyName}` : '-'}
                portal
                useJsPositioning
                contentShowDelayOnHover={0}
                contentHideDelayOnHover={0}
              >
                <UserName className="label" data-testid="user-name-label">
                  {getUserFullName(user.givenName, user.familyName)}
                </UserName>
              </SmartTooltip>
            )}
          </UserInfo>
        )
      }
      const getEmailAddress = (email: string) => {
        return (
          <ColumnPiece>
            <SmartTooltip
              content={email}
              portal
              useJsPositioning
              contentShowDelayOnHover={0}
              contentHideDelayOnHover={0}
            >
              <UserName className="label" data-testid="user-name-label">
                {email}
              </UserName>
            </SmartTooltip>
          </ColumnPiece>
        )
      }
      return usersData.map((user, index) => {
        const tooltipChild = getUserName(user)
        const tooltipEmail = getEmailAddress(user.email)
        const ContextualMenuHelper = (child) => (
          <ColumnPiece>
            <ContextualMenuArea
              data-testid="contextual-menu-activate"
              id={`${index}`}
              onContextMenu={(event) => {
                if (activeUser?.userResourceId !== user.resourceId) {
                  handleClick(event, index)
                  onDeleteItem({
                    tenantResourceId: user.tenantResourceId,
                    resourceId: user.resourceId
                  })

                  setIsContextualMenuOpen(true)
                  setSelectedUser(user)
                  setDisableResendInvitation(user.state === 'Active')
                }
              }}
            />
            {child}
          </ColumnPiece>
        )
        return {
          ...user,
          email: !showEmailInTooltip ? tooltipEmail : ContextualMenuHelper(user.email),
          emailLabel: user.email,
          fullName: `${user.givenName || ''} ${user.familyName || ''}`,
          groups: 'All Users',
          name: ContextualMenuHelper(tooltipChild),
          ownerTenant: user.ownerTenant || false,
          role: ContextualMenuHelper(user.roleLabel),
          roleLabel: user.roleLabel,
          rowConfig: {
            noWrap: false,
            selected: false,
            disabled: activeUser?.userResourceId === user.resourceId
          },
          solutions: 'Endpoint Security',
          status: ContextualMenuHelper(
            <UserStatus data-testid={`user-status-${user.resourceId}`}>
              <IconCircle
                data-testid="user-status-circle"
                color={getStatusIconColor(user?.state, statusColorMapping)}
                filled
                size={12}
                customStyle={getStatusIconThemeColor(user?.state, statusColorMapping, theme)}
              />
              <span className="label">{t(getl10n(user.stateLabel))}</span>
            </UserStatus>
          ),
          uid: index
        }
      })
    },
    [activeUser, statusColorMapping, t, theme, handleClick, onDeleteItem, showEmailInTooltip]
  )

  useEffect(() => {
    const newUserData = buildUsersData(users)
    sort(newUserData, UserListConstants.fullName, orderType)
    setUsersData(newUserData)
  }, [buildUsersData, users, orderType])

  useEffect(() => {
    if (activeUser && !storageLoaded) {
      setStorageLoaded(true)
      if (orderSaved) {
        const { orderColumn } = JSON.parse(orderSaved)
        setOrderColumn(orderColumn)
      }
    }
  }, [orderSaved, activeUser, storageLoaded])

  const handleDeSelect = useCallback(() => {
    if (usersData.length !== 0) {
      setUsersData(deSelectRow(usersData, false))
    }
  }, [usersData])

  const handleClose = useCallback(() => {
    document.querySelectorAll('tr').forEach((elem) => {
      elem.removeAttribute('id')
      setPosition(initialState)
    })
    setIsContextualMenuOpen(false)
  }, [])

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page)

      const errorText = Array.from(document.getElementsByTagName('div'))
      errorText.forEach((item) => {
        if (item && item.textContent === t(getl10n('noItemsFound'))) {
          const element = (
            <div className="noCustomerBlock">
              <IconWarningAlt id="warning-icon-users" />
              <p className="no-users-label" data-testid="no-users-label">
                {t(getl10n('noUserLabel'))}
              </p>
              {displayNoResultsFilterText && (
                <div className="invite-users-label">
                  <p data-testid="invite-users-label">{t(getl10n('invite'))}</p>
                </div>
              )}
            </div>
          )
          ReactDOM.render(element, item)
        }
      })

      if (searchItem !== '') {
        const invalidText = Array.from(document.getElementsByTagName('p'))
        invalidText.forEach((item) => {
          if (item && item.textContent === t(getl10n('noUserLabel'))) {
            const element = (
              <div className="noResultsBlock">
                <p className="no-results-label" data-testid="no-results-label">
                  {t(getl10n('noResults'))}
                </p>
              </div>
            )
            ReactDOM.render(element, item)
          }

          if (item && item.textContent === t(getl10n('invite')) && displayNoResultsFilterText) {
            const element = (
              <div className="noResultsBlock">
                <p className="invalid-search-label" data-testid="invalid-search-label">
                  {t(getl10n('searchKeywordContent'))}
                </p>
              </div>
            )
            ReactDOM.render(element, item)
          }
        })
      }
      addDataTestIdToNoItems()
      addDataTestIdToPagination(currentPage, totalItems)
    },
    [searchItem, currentPage, totalItems, t, displayNoResultsFilterText]
  )

  const handlePageSizeChange = useCallback((event: React.MouseEvent, option: OptionInterface<number>) => {
    event.preventDefault()
    setPageSize(option.value)
  }, [])

  const handleSort = useCallback(
    (_: React.MouseEvent<HTMLElement, MouseEvent>, { id }: TableSortBy) => {
      let type: SortTypes = 'ascending'
      const tempId = id
      if (id === UserListConstants.name) {
        id = UserListConstants.fullName
      }
      if (id === UserListConstants.status) {
        id = UserListConstants.state
      }
      if (id === UserListConstants.email) {
        id = UserListConstants.emailLabel
      }
      if (id === UserListConstants.role) {
        id = UserListConstants.roleLabel
      }
      if (orderBy === id) {
        setOrderType((prevOrderType) => (prevOrderType === 'ascending' ? 'descending' : 'ascending'))
        type = orderType === 'ascending' ? 'descending' : 'ascending'
      } else {
        setOrderType('ascending')
        setOrderBy(id)
      }
      const regSorting = {
        id: tempId,
        type: type
      }
      setDefOrderBy(regSorting)
    },
    [orderBy, orderType]
  )

  useEffect(() => {
    handleResetToDefaultState(userListColumnDefaultOrder, orderColumn, setDisableResetToDefault)
  }, [orderColumn, userListColumnDefaultOrder])

  const getPreferences = useCallback(() => {
    const defaultOrder = userListColumnDefaultOrder.filter((item) => {
      return (item !== 'groups' || !!displayGroupColumn) && (item !== 'solutions' || !hideSolutionsColumn)
    })
    const localPreferences = {
      ...UserListPreferences,
      order: orderColumn,
      defaultOrder,
      sortBy: defOrderBy,
      disableResetToDefault
    }
    return localPreferences
  }, [
    defOrderBy,
    disableResetToDefault,
    displayGroupColumn,
    hideSolutionsColumn,
    orderColumn,
    userListColumnDefaultOrder
  ])

  const openToast = useCallback(
    (selectedItems, error) => {
      const type = error ? 'negative' : 'positive'
      const id: string = error ? 'error-remove' : 'success'

      let text: string

      if (error) {
        text =
          selectedItems > 1
            ? t(getl10n('removeUserErrorToastPlural'), {
                num: selectedItems
              })
            : t(getl10n('removeUserErrorToast'))
      } else {
        text =
          selectedItems > 1
            ? t(getl10n('removeUserSuccessToastPlural'), {
                num: selectedItems,
                numberOfSelectedItems: selectedItems.toString()
              })
            : t(getl10n('removeUserSuccessToast'), {
                num: selectedItems,
                numberOfSelectedItems: selectedItems.toString()
              })
      }

      addToast({
        id,
        type,
        text
      })
    },
    [addToast, t]
  )

  const onDeleteMultipleUsers = useCallback(async () => {
    setModalLoading(true)

    try {
      const deletedUsers = await Promise.allSettled(
        selectedItems.map(async (item: UserData) => {
          await deleteInvitation({
            id: item.tenantResourceId,
            resourceId: item.resourceId,
            authProvider,
            stack
          })
        })
      )

      const sucessfullyDeletedUsers = deletedUsers
        .filter((result) => result.status === 'fulfilled')
        .map((result) => result)

      const errorDeletedUsers = deletedUsers.filter((result) => result.status === 'rejected').map((result) => result)

      if (sucessfullyDeletedUsers.length) {
        openToast(sucessfullyDeletedUsers.length, false)
      }

      if (errorDeletedUsers.length) {
        openToast(errorDeletedUsers.length, true)
      }

      setShowDeleteModal(false)
      retrieveUsers()
    } finally {
      setModalLoading(false)
    }
  }, [openToast, retrieveUsers, selectedItems, authProvider, stack])

  const onDeleteSingleUser = useCallback(async () => {
    setModalLoading(true)

    try {
      const deletedUser = await deleteInvitation({
        id: deletedRowId['tenantResourceId'],
        resourceId: deletedRowId['resourceId'],
        stack,
        authProvider
      })

      if (deletedUser) {
        openToast(1, false)
      }

      setShowDeleteModal(false)
      retrieveUsers()
    } catch (error) {
      openToast(1, true)
    } finally {
      setModalLoading(false)
    }
  }, [deletedRowId, openToast, retrieveUsers, authProvider, stack])

  const onDeleteUser = useCallback(() => {
    if (selectedItems.length > 0) {
      onDeleteMultipleUsers()
    } else {
      onDeleteSingleUser()
    }

    const { countAdmin, countUser } = getCountByRole(permissionSet, selectedItems)

    if (countAdmin > 0) {
      publishEvent(ContinueButtonClicked('AdminsRemoved', countAdmin))
    }

    if (countUser > 0) {
      publishEvent(ContinueButtonClicked('UsersRemoved', countUser))
    }
  }, [onDeleteMultipleUsers, onDeleteSingleUser, selectedItems, permissionSet])

  const handleSelect = (event: React.ChangeEvent, index: number | string) => {
    setUsersData(selectRow(usersData, event.target['checked'], index as number))
    setPaginationData()
  }

  const handleOption = useCallback((_, item) => {
    const option = item ? item.value : ''

    switch (option) {
      case 'assignRole':
        setShowAssignRoleModal(true)
        break
      // TODO: is it still necessary
      // Enablement this options under review
      // case 'assignSolutions':
      //   evt.preventDefault()
      //   break
      case 'resendInvitation':
        setShowResendInvitationModal(true)
        break
      default:
        setShowDeleteModal(true)
        break
    }
  }, [])

  const setPaginationData = useCallback(() => {
    const startIndex = (currentPage - 1) * pageSize
    const pageData: UserData[] = filter(usersData, permissionSet, searchItem, searchItems).slice(
      startIndex,
      startIndex + pageSize
    )
    setUserPageData(pageData)
  }, [currentPage, pageSize, searchItem, searchItems, usersData, permissionSet])

  //EFFECTS

  useEffect(() => {
    setPaginationData()
  }, [currentPage, pageSize, setPaginationData])

  useEffect(() => {
    setUserPageData(sort(usersData, orderBy, orderType))
    setPaginationData()
  }, [orderType, orderBy, usersData, setPaginationData])

  useEffect(() => {
    const filterData = filter(usersData, permissionSet, searchItem, searchItems)
    setTotalItems(filterData.length)
    const currentStartIndex = (currentPage - 1) * pageSize
    const startIndex = filterData.length > currentStartIndex ? currentStartIndex : 0
    const pageData: UserData[] = filterData.slice(startIndex, startIndex + pageSize)
    setUserPageData(pageData)
    setTimeout(() => {
      handlePageChange(filterData.length > pageSize ? currentPage : 1)
    }, 100)
    disableTitleOnSvgIcon()
  }, [currentPage, handlePageChange, pageSize, searchItem, searchItems, usersData, permissionSet])

  useEffect(() => {
    if (storageLoaded) {
      const { defaultOrder } = getPreferences()
      const content = {
        defaultOrder,
        orderColumn
      }

      localStorage.setItem(storageColumn, JSON.stringify(content))
    }
  }, [getPreferences, orderColumn, storageColumn, storageLoaded])

  const handleSelectAllPageItems = useCallback(
    (event: React.ChangeEvent) => {
      const isIndeterminate = event.target.getAttribute('data-indeterminate') === 'true'
      setUserPageData(handleMultipleUsers(userPageData, event.target['checked'], isIndeterminate))
      const selectedUserList: UserData[] = mergeData(usersData, userPageData)
      setUsersData(selectedUserList)
    },
    [userPageData, usersData]
  )

  useEffect(() => {
    const userListColumnsLocal = userListColumns.filter(
      (item) => (item.id !== 'groups' || !!displayGroupColumn) && (item.id !== 'solutions' || !hideSolutionsColumn)
    )
    setColumns(userListColumnsLocal)
  }, [displayGroupColumn, hideSolutionsColumn, userListColumns])

  useEffect(() => {
    if (handleClickTriggered) {
      setHandleClickTriggered(false)
      handleDeSelect()
    }
  }, [handleClickTriggered, handleDeSelect])

  const getInvitationMessage = useCallback(
    (amountInvites, isSuccess) => {
      if (isSuccess) {
        return amountInvites > 1
          ? t(getl10n('invitationSuccessUsersPlural'), { num: amountInvites })
          : t(getl10n('invitationSuccessUsers'))
      }

      return amountInvites > 1
        ? t(getl10n('invitationFailureUsersPlural'), { num: amountInvites })
        : t(getl10n('invitationFailureUsers'))
    },
    [t]
  )

  const handleSelectAllOrDeselectAll = useCallback(
    (selected: boolean) => {
      setUsersData(handleMultipleUsers(usersData, selected, true))
      setPaginationData()
    },
    [setPaginationData, usersData]
  )

  const callResendInvitation = useCallback(async () => {
    let reinviteSuccesses = 0
    let reinviteFailures = 0

    setModalLoading(true)

    try {
      if (numberOfSelectedItems > 0) {
        await Promise.all(
          selectedItems.map((item) =>
            resendInvitation({
              tenantResourceId: item.tenantResourceId,
              userResourceId: item.resourceId,
              authProvider,
              stack
            })
              .then(() => reinviteSuccesses++)
              .catch(() => reinviteFailures++)
          )
        )

        const { countAdmin, countUser } = getCountByRole(permissionSet, selectedItems)

        if (countAdmin > 0) {
          publishEvent(ContinueButtonClicked('ResendInvitationAdmin', countAdmin))
        }

        if (countUser > 0) {
          publishEvent(ContinueButtonClicked('ResendInvitationUser', countUser))
        }
      } else {
        await resendInvitation({
          tenantResourceId: selectedUser.tenantResourceId,
          userResourceId: selectedUser.resourceId,
          authProvider,
          stack
        })
          .then(() => reinviteSuccesses++)
          .catch(() => reinviteSuccesses++)
      }
    } finally {
      if (reinviteSuccesses > 0) {
        addToast({
          id: 'resend-invitation-success',
          type: 'positive',
          text: getInvitationMessage(reinviteSuccesses, true)
        })
      }

      if (reinviteFailures > 0) {
        addToast({
          id: 'resend-invitation-negative',
          type: 'negative',
          text: getInvitationMessage(reinviteFailures, false)
        })
      }

      setShowResendInvitationModal(false)
      handleSelectAllOrDeselectAll(false)
      setModalLoading(false)
    }
  }, [
    addToast,
    authProvider,
    getInvitationMessage,
    selectedUser,
    stack,
    handleSelectAllOrDeselectAll,
    numberOfSelectedItems,
    permissionSet,
    selectedItems
  ])

  const assignRoleToUser = useCallback(
    async (rolePermId) => {
      const showToastAssingRole = (numberItems, error) => {
        const type = error ? 'negative' : 'positive'
        const id: string = error ? 'assign-role-negative' : 'assign-role-success'
        let text: string

        if (error) {
          text =
            numberItems > 1
              ? t(getl10n('assignRoleErrorToastPluralDefault'), { num: numberItems })
              : t(getl10n('assignRoleErrorToastDefault'))
        } else {
          text =
            numberItems > 1
              ? t(getl10n('assignRoleSuccessToastPlural'), { num: numberItems })
              : t(getl10n('assignRoleSuccessToast'))
        }

        addToast({
          id,
          text,
          type
        })
      }

      const requestRoleChange = async (rolePermId, resourceId, tenantResourceId, state) => {
        const assignRolePromise =
          state === 'Active'
            ? assignUserTenantRole({
                roleResourceId: rolePermId,
                tenantResourceId: tenantResourceId,
                userResourceId: resourceId,
                authProvider,
                stack
              })
            : assignExternalUserRole({
                roleResourceId: rolePermId,
                userResourceId: resourceId,
                stack,
                authProvider
              })

        await assignRolePromise
      }

      setModalLoading(true)

      try {
        if (numberOfSelectedItems > 0) {
          const usersToAddRole = selectedItems.map(async (item) =>
            requestRoleChange(rolePermId, item.resourceId, item.tenantResourceId, item.state)
          )
          const assignRoleResult = await Promise.allSettled(usersToAddRole)
          const assignRoleSucess = assignRoleResult.filter((result) => result.status === 'fulfilled')
          const assignRoleError = assignRoleResult.filter((result) => result.status === 'rejected')

          if (assignRoleSucess.length) {
            showToastAssingRole(assignRoleSucess.length, false)
          }

          if (assignRoleError.length) {
            showToastAssingRole(assignRoleError.length, true)
          }

          setShowAssignRoleModal(false)
          handleDeSelect()
          retrieveUsers()
        } else {
          await requestRoleChange(
            rolePermId,
            selectedUser?.resourceId,
            selectedUser?.tenantResourceId,
            selectedUser?.state
          )

          setShowAssignRoleModal(false)
          retrieveUsers()
        }

        const { countAdmin, countUser } = getCountByRole(permissionSet, selectedItems)
        const role = getPermissionSetById(permissionSet, rolePermId)

        if (countAdmin > 0) {
          if (role?.type === 'admin') {
            publishEvent(ContinueButtonClicked('AssignRoleAdminToAdmin', countAdmin))
          } else if (role?.type === 'user') {
            publishEvent(ContinueButtonClicked('AssignRoleAdminToUser', selectedItems.length - countUser))
          }
        }

        if (countUser > 0) {
          if (role?.type === 'admin') {
            publishEvent(ContinueButtonClicked('AssignRoleUserToAdmin', selectedItems.length - countAdmin))
          } else if (role?.type === 'user') {
            publishEvent(ContinueButtonClicked('AssignRoleUserToUser', countUser))
          }
        }
      } finally {
        setModalLoading(false)
      }
    },
    [
      authProvider,
      permissionSet,
      stack,
      addToast,
      handleDeSelect,
      numberOfSelectedItems,
      retrieveUsers,
      selectedItems,
      selectedUser?.resourceId,
      selectedUser?.state,
      selectedUser?.tenantResourceId,
      t
    ]
  )

  /**
   * Get the selected user's role.
   * This information is used to disable the role in AssignRoleModal.
   * when it is being used to change the role of a single user.
   */
  const getSelectedUserRole = useCallback(() => {
    if (numberOfSelectedItems === 0) {
      if (selectedUser) {
        return selectedUser.roleLabel
      }
    } else if (numberOfSelectedItems === 1) {
      return selectedItems[0].role
    }
    return null
  }, [numberOfSelectedItems, selectedUser, selectedItems])

  const customNoItems = useMemo(() => {
    if (hasError) {
      return (
        <div data-testid="error-users">
          <ErrorWidget onRetry={retrieveUsers} message={t(getl10n('unableToLoad'))} labelRetry={t(getl10n('retry'))} />
        </div>
      )
    }

    return null
  }, [hasError, retrieveUsers, t])

  const data = hasError ? [] : userPageData

  return (
    <>
      {!useGlobalHeader && <ReorderColumnPortal data-testid="reorder-column-modal-portal" ref={reorderColumnRef} />}
      <Table
        columnReorderPortalContainer={reorderColumnRef?.current}
        actionArea={actionArea}
        columnReorder={columnReorder}
        columns={columns}
        customNoItems={customNoItems}
        data-testid="users-data-table"
        data={data}
        i18n={{
          actionButton: '',
          cancelButton: t(getl10n('modalCancel')),
          clearAllFilters: 'Clear Filters',
          columnOptions: t(getl10n('columnModalTitle')),
          columnResizeTooltip: '',
          deselectAllItems: 'Deselect all',
          downButton: 'move selected column down',
          itemSelected: '%s items selected',
          itemsSelected: '%s item selected',
          noItems: t(getl10n('noItemsFound')),
          resetToDefault: displayColumnResetText ? t(getl10n('columnModalResetECP')) : t(getl10n('columnModalReset')),
          saveButton: t(getl10n('modalSave')),
          selectAllItems: 'Select all %{totalNumberOfItems} items',
          selectAllPageItems: 'select all items in the current page',
          sortedAscending: 'ascending sorted',
          sortedDescending: 'descending sorted',
          upButton: 'move selected column up'
        }}
        loading={isLoading}
        loadingDataLength={3}
        onColumnReorder={(value) => setOrderColumn(value as string[])}
        onDeselectAllItems={() => handleSelectAllOrDeselectAll(false)}
        onSelect={handleSelect}
        onSelectAllItems={displaySelectAllItemsButton ? () => handleSelectAllOrDeselectAll(true) : undefined}
        onSelectAllPageItems={handleSelectAllPageItems}
        onSort={handleSort}
        pagination={{
          currentPage,
          i18n: {
            currentPage: 'Page %{pageNumber} (Current Page)',
            goto: 'Go to page %{pageNumber}',
            next: 'Next',
            paginationNavigation: 'Pagination Navigation',
            pageSizeInformation: t(getl10n('paging')),
            previous: 'Previous',
            selectPageSize: 'Select page size'
          },
          onPageChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptions || defaultPageSizeOptions,
          popoverPlacement: popoverPlacement || 'top-start',
          totalItems
        }}
        preferences={getPreferences()}
        rowSelector={'multiSelection'}
        rowSelectAllState={selectionState(userPageData)}
      />

      <AssignRoleModal
        data-testid="assign-role-modal"
        isLoading={modalLoading}
        modalTitle={t(getl10n('actionAssignRole'))}
        onConfirm={assignRoleToUser}
        onClose={() => {
          setShowAssignRoleModal(false)
        }}
        selectedUserRole={getSelectedUserRole()}
        show={showAssignRoleModal}
        rolesDataSource={rolesDataSource}
        permissionSet={permissionSet}
      />

      <ContextualMenu
        anchorNode={<div />}
        anchorReference="position"
        data-testid="contextual-menu"
        id="contextual-right-click"
        onClick={handleOption}
        onClose={handleClose}
        open={isContextualMenuOpen}
        placement="trailing-start"
        position={position}
      >
        <MenuList>{contextualMenuItems}</MenuList>
      </ContextualMenu>

      <RemoveUserModal
        data-testid="remove-user-modal"
        forFilterScreen={false}
        hideContentModalRemoveUser={hideContentModalRemoveUser}
        modalContent={
          numberOfSelectedItems > 1
            ? t(getl10n('userRemoveModalContentMulti'))
            : t(getl10n('userRemoveModalContentSingle'))
        }
        modalSubcontent={showRemoveUserModalSubcontent}
        modalTitle={
          numberOfSelectedItems > 1 ? t(getl10n('usersRemoveModalTitle')) : t(getl10n('userRemoveModalTitle'))
        }
        onDelete={onDeleteUser}
        onClose={() => {
          setShowDeleteModal(false)
        }}
        showModal={showDeleteModal}
        isLoading={modalLoading}
      />

      <ResendInvitationModal
        data-testid="resend-invitation-modal"
        isLoading={modalLoading}
        onClose={() => {
          setShowResendInvitationModal(false)
        }}
        onConfirm={callResendInvitation}
        show={showResendInvitationModal}
      />

      <ContextualFooter
        data-testid="contextual-footer"
        deleteScopeForUser={deleteScopeForUser}
        onAssignRole={() => {
          setShowAssignRoleModal(true)
        }}
        onCancel={() => {
          handleSelectAllOrDeselectAll(false)
        }}
        onDelete={() => {
          setShowDeleteModal(true)
          setDeletedRowId(null)
        }}
        onResendInvitation={() => {
          setShowResendInvitationModal(true)
        }}
        selectedItems={selectedItems}
        writeScopeForAssignRole={writeScopeForAssignRole}
        writeScopeForInvite={writeScopeForInvite}
      />
    </>
  )
}

export default Users
